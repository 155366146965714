import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UPLOAD_URL } from "../util/Config";
import { AppContext } from "../context/AppContext";
import { parseJwt } from "../util/TokenHelper";
import { Navbar, Container, Dropdown, DropdownButton } from "react-bootstrap";

function CabeceraComponent(){

    const { token, logout } = useContext(AppContext);
    const { data: usuario } = parseJwt(token);
    const navigate = useNavigate();

    const handleSelect = (url) => {
        if(url!=='/logout'){
            navigate(url);
        }else{
           logout();
        }
    };

    return(
        <>
        {usuario &&
            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Navbar.Brand>
                        <img src={UPLOAD_URL+'/shared/'+usuario.logo} style={{height:'40px'}} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/principal">Inicio</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/historial">Historial</Link>
                            </li>
                        </ul>
                        <Dropdown>
                            <DropdownButton onSelect={handleSelect} variant="light" title={<>{usuario.nombres}</>} align="end">
                                <Dropdown.Item eventKey="/perfil"><i className="bi bi-person-fill"></i> Perfil</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item eventKey="/logout"><i className="bi bi-door-closed-fill"></i> Salir</Dropdown.Item>
                            </DropdownButton>
                        </Dropdown>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        }
        </>
    );
}

export default CabeceraComponent;