import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppProvider } from "./context/AppContext";
import AuthGuard from "./util/AuthGuard";
import CabeceraComponent from "./components/CabeceraComponent";
import LoginPage from "./pages/LoginPage";
import PrincipalPage from "./pages/PrincipalPage";
import HistorialPage from "./pages/HistorialPage";
import PerfilPage from "./pages/PerfilPage";
import CursoPage from "./pages/CursoPage";
import UnidadPage from "./pages/UnidadPage";
import MaterialPage from "./pages/MaterialPage";
import LaboratorioPage from "./pages/LaboratorioPage";
import TareaPage from "./pages/TareaPage";
import TareasPage from "./pages/TareasPage";
import CalificacionesPage from "./pages/CalificacionesPage";
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <AppProvider>
        <CabeceraComponent />
        <Routes>
          <Route exact path="/" element={<LoginPage />} />
          <Route exact path="/principal" element={<AuthGuard component={PrincipalPage} />} />
          <Route exact path="/historial" element={<AuthGuard component={HistorialPage} />} />
          <Route exact path="/perfil" element={<AuthGuard component={PerfilPage} />} />
          <Route exact path="/curso/:idcurso" element={<AuthGuard component={CursoPage} />} />
          <Route exact path="/unidad/:idunidad" element={<AuthGuard component={UnidadPage} />} />
          <Route exact path="/material/:idmaterial" element={<AuthGuard component={MaterialPage} />} />
          <Route exact path="/laboratorio/:idmaterial" element={<AuthGuard component={LaboratorioPage} />} />
          <Route exact path="/tarea/:idmaterial" element={<AuthGuard component={TareaPage} />} />
          <Route exact path="/curso/tareas/:idcurso" element={<AuthGuard component={TareasPage} />} />
          <Route exact path="/curso/calificaciones/:idcurso" element={<AuthGuard component={CalificacionesPage} />} />
          <Route path="*" element={<h4 className="mt-3 text-center">Error 404 - Página No Encontrada</h4>} />
        </Routes>
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
