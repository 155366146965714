import { useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { AppContext } from '../context/AppContext';
import { useGoogleLogin } from 'react-google-login';
import Swal from 'sweetalert2';
import { loginAlumnoService } from '../services/AlumnoService';
import '../login.css';

function LoginPage(){

    const navigate = useNavigate();
    let { login, token } = useContext(AppContext);

    useEffect(()=>{
        if(token){
            navigate('/principal');
        }
    },[]);

    const onSuccess = async (user) => {
        let datos = {
            email: user.profileObj.email,
        }
        const res = await loginAlumnoService(datos);
        if(res.status===200){
            const datos = res.data;
            if(datos.token){
                login(datos.token);
                navigate('/principal');
            }else{
                Swal.fire(
                    'Sin Acceso',
                    'Su cuenta de correo no tiene acceso a la plataforma',
                    'error'
                );
            }
        }
    }

    const onFailure = (response) => {
        console.log(response);
    }

    const { signIn } = useGoogleLogin({
        onSuccess,
        onFailure,
        clientId: '397335001810-cq9mhdbd9b90qio4vuqn273hfkhnsle1.apps.googleusercontent.com',
        isSignedIn: false,
        accessType: 'offline',
    });

    return(
        <main className='mt-3'>
            <div className="form-signin">
                    <div className="text-center">
                        <h1 className="h3 mb-2 fw-normal">Iniciar Sesión</h1>
                        <p>Accede a este sitio Web autenticandote.</p>
                    </div>
                    <div className="cp-login-box">
                        <div className="user-pic"><img src="/usuario.jpg" alt="" /></div>
                        <div className="cp-login-form">
                            <ul className="cp-social-login">
                                <li><h4>Inicie sesión con</h4></li>
                                <li>
                                    <div className="btn-group w-100">
                                        <button type="button" className="btn btn-danger disabled"><i className="bi bi-google"></i></button>
                                        <button onClick={signIn} className="w-100 btn btn-danger">GOOGLE</button>
                                    </div>
                                </li>
                            </ul>
                            <div className="signup">
                                &copy; 2021
                            </div>
                        </div>
                    </div>
            </div>
        </main>
    );
}

export default LoginPage;
