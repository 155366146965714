import { Api } from "../util/Api";

export const cursoListarService = async (estado) => {
    const res = await Api().get('/cursos/'+estado);
    return res;
}

export const cursoDetalleService = async (id) => {
    const res = await Api().get('/curso/unidades/'+id);
    return res;
}

export const cursoTareasService = async (id) => {
    const res = await Api().get('/curso/tareas/'+id);
    return res;
}

export const cursoCalificacionesService = async (idalumno,id) => {
    const res = await Api().get(`/curso/calificaciones/${idalumno}/${id}`);
    return res;
}

export const unidadDetalleService = async (id) => {
    const res = await Api().get('/unidad/materiales/'+id);
    return res;
}

export const materialDetalleService = async (id) => {
    const res = await Api().get('/material/'+id);
    return res;
}
