import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { cursoDetalleService } from "../services/CursoServices";
import MenuComponent from "../components/MenuComponent";

function CursoPage(){

    const { idcurso } = useParams();
    const [curso,setCurso] = useState({});
    const [unidades,setUnidades] = useState([]);

    useEffect(()=>{
        async function fetchData() {
            const res = await cursoDetalleService(idcurso);
            setCurso(res.data.curso);
            setUnidades(res.data.unidades);
        }
        fetchData();
    },[idcurso]);

    return(
        <main className="container mt-3">
            <nav className="mb-3 border-bottom">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/principal">Mis Cursos</Link></li>
                    <li className="breadcrumb-item active">{curso.nombre}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-3 mb-3">
                    <MenuComponent idcurso={curso.id} estado={curso.estado} />
                </div>
                <div className="col-md-9">
                {unidades.map((item, index)=>(
                    <div key={index} className="card mb-3">
                        <h4 className="card-header">
                            {item.nombre}
                        </h4>
                        <div className="list-group list-group-flush">
                        {item.materiales.map((material, idx)=>(
                            <Link key={idx} to={material.tipo==='P' ? "/material/"+material.id : "/tarea/"+material.id } className="list-group-item list-group-item-action" >
                                <i className="bi bi-card-list"></i> {material.nombre}
                            </Link>
                        ))}
                        </div>
                    </div>
                ))}
                </div>
            </div>
        </main>
    );
}

export default CursoPage;