import { Link } from "react-router-dom";

function MenuComponent(props){
    const { idcurso, estado } = props;
    return(
        <div className="list-group mb-3">
            {estado=='A' && (
            <Link to={"/curso/tareas/"+idcurso} className="list-group-item list-group-item-action">
                <i className="bi bi-pencil-square"></i> Tareas
            </Link>
            )}
            <Link to={"/curso/calificaciones/"+idcurso} className="list-group-item list-group-item-action">
                <i className="bi bi-clipboard-check"></i> Calificaciones
            </Link>
        </div>
    );
}

export default MenuComponent;