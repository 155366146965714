import { UPLOAD_URL, DIR_DOMAIN } from "../util/Config";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { materialDetalleService } from "../services/CursoServices";
import MenuComponent from "../components/MenuComponent";
import PDFViewer from "../components/PDFViewer";

function MaterialPage() {

    const { idmaterial } = useParams();
    const [material,setMaterial] = useState({});
    const [recursos,setRecursos] = useState([]);
    const [adjuntos,setAdjuntos] = useState([]);

    useEffect(async ()=>{
        const { data } = await materialDetalleService(idmaterial);
        setMaterial(data);
        setRecursos(JSON.parse(data.recursos));
        setAdjuntos(JSON.parse(data.adjuntos));
    },[]);

    return(
        <main className="container mt-3">
            <nav className="mb-3 border-bottom">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/principal"}>Mis Cursos</Link></li>
                    <li className="breadcrumb-item"><Link to={"/curso/"+material.curso_id}>{material.curso}</Link></li>
                    <li className="breadcrumb-item"><Link to={"/unidad/"+material.unidad_id}>{material.unidad}</Link></li>
                    <li className="breadcrumb-item active">{material.nombre}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-3 mb-3">
                    <MenuComponent idcurso={material.curso_id} />
                    {/*<div className="list-group">
                        <a href="material.html" className="list-group-item list-group-item-action active" aria-current="true">
                        Tema 01
                        </a>
                        <a href="material.html" className="list-group-item list-group-item-action">Tema 02</a>
                        <a href="tarea.html" className="list-group-item list-group-item-action">Tarea 01</a>
                    </div>*/}
                    <div className="mt-3">
                        <Link to={"/unidad/"+material.unidad_id} className="btn btn-outline-dark w-100">Ver todos los temas</Link>
                    </div>
                </div>
                <div className="col-md-9">
                    <h4 className="mb-3 d-flex justify-content-between">
                        <Link to={"/unidad/"+material.unidad_id}>{material.unidad}</Link>
                        <Link className="btn btn-dark btn-sm" to={"/laboratorio/"+idmaterial}><i className="bi bi-file-earmark-code-fill"></i> Ver Laboratorio</Link>
                    </h4>
                    <div className="card mb-3">
                        <h6 className="card-header"><i className="bi bi-bookmark"></i> {material.nombre}</h6>
                        <div className="card-body">
                            <div dangerouslySetInnerHTML={{__html: material.descripcion}}></div>
                            {(recursos) && (<>
                            <h6 className="fw-bold">Recursos:</h6>
                            <div className="list-group list-group-flush">
                            {recursos.map((item,idx)=>(
                                (idx===0 && item.tipo==='pdf')?(
                                    <div key={idx}>
                                        <PDFViewer archivo={item.archivo} />
                                    </div>
                                ):(
                                    <a key={idx} href={`${UPLOAD_URL}/${DIR_DOMAIN}/materiales/${item.archivo}`} target={"_blank"} className="list-group-item list-group-item-action" >
                                        <i className="bi bi-paperclip"></i> {item.descripcion}
                                    </a>
                                )
                            ))}
                            </div>
                            </>)}
                            {(adjuntos) && (<>
                            <h6 className="fw-bold">Material de clase:</h6>
                            <div className="list-group list-group-flush">
                            {adjuntos.map((item,idx)=>(
                                <a key={idx} href={`${UPLOAD_URL}/${DIR_DOMAIN}/cursos/${material.curso_codigo}/adjuntos/${item.archivo}`} target={"_blank"} className="list-group-item list-group-item-action" >
                                    <i className="bi bi-paperclip"></i> {item.archivo}
                                </a>
                            ))}
                            </div>
                            </>)}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default MaterialPage;