import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { cursoTareasService } from "../services/CursoServices";
import MenuComponent from "../components/MenuComponent";

function TareasPage() {

    const { idcurso } = useParams();
    const [curso,setCurso] = useState({});
    const [tareas,setTareas] = useState([]);

    useEffect(async ()=>{
        const res = await cursoTareasService(idcurso);
        setCurso(res.data.curso);
        if(res.data.tareas!==undefined){
            setTareas(res.data.tareas);
        }
    },[]);

    return(
        <main className="container mt-3">
            <nav className="mb-3 border-bottom">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/principal">Mis Cursos</Link></li>
                    <li className="breadcrumb-item"><Link to={"/curso/"+curso.id}>{curso.nombre}</Link></li>
                    <li className="breadcrumb-item active">Tareas</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-3 mb-3">
                    <MenuComponent idcurso={curso.id} />
                    <div className="mt-3">
                        <Link to={"/curso/"+curso.id} className="btn btn btn-outline-dark w-100">Ver curso</Link>
                    </div>
                </div>
                <div className="col-md-9">
                    <h4 className="mb-3"><i className="bi bi-journal-text"></i> {curso.nombre}</h4>
                    <div className="card mb-3">
                        <h5 className="card-header">Tareas</h5>
                        <div className="list-group list-group-flush">
                        {tareas.length>0 ? tareas.map((tarea,index)=>(
                            <Link key={index} to={"/tarea/"+tarea.id} className="list-group-item list-group-item-action">
                                <div className="d-flex justify-content-between">
                                    <div><i className="bi bi-pencil-square"></i> {tarea.nombre}</div>
                                    {/*<span className="badge bg-secondary">Pendiente</span>*/}
                                </div>
                                <div>Vencimiento: {tarea.fecha_entrega}</div>
                            </Link>
                        )) : (
                            <div className="p-2 text-center">No hay tareas asignadas</div>
                        )}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default TareasPage;