import { UPLOAD_URL, DIR_DOMAIN } from "../util/Config";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { cursoListarService } from "../services/CursoServices";

function HistorialPage(){

    const [listaCursos,setListaCursos] = useState([]);

    const listarCursos = async () => {
        const res = await cursoListarService('finalizados');
        setListaCursos(res.data);
    }

    useEffect(()=>{
        listarCursos();
    },[]);

    return(
        <main className="container mt-3">
            <div className="pt-3-md-3 pb-2 mb-3 border-bottom">
                <h2>Historial</h2>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                    {listaCursos.map((item,index)=>(
                        <div key={index} className="col-md-3 mb-3">
                            <div className="card">
                                <img src={`${UPLOAD_URL}/${DIR_DOMAIN}/materiales/${item.imagen}`} className="card-img-top" alt="curso" />
                                <div className="card-body">
                                    <h5 className="card-title">{item.nombre}</h5>
                                    <p className="card-text">
                                        <small className="text-muted">
                                            {item.descripcion}<br/>
                                            {item.codigo}
                                        </small>
                                    </p>
                                    <Link to={"/curso/"+item.id} className="btn btn-dark w-100">Ingresar al curso</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        </main>
    );
}

export default HistorialPage;