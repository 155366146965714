import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

let AppContext = React.createContext();
let { Provider, Consumer } = AppContext;

function AppProvider({children}){
    const navigate = useNavigate();
    const [token,setToken] = useState(localStorage.token || null);
    function login(token){
        localStorage.token = token;
        setToken(token);
    }
    function logout(){
        localStorage.removeItem("token");
        setToken(null);
        navigate("/");
    }
    return(
        <Provider value={{token,setToken,login,logout}}>
            {children}
        </Provider>
    );
};

export { AppProvider, Consumer as AppConsumer, AppContext };
