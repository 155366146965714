import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { parseJwt } from "../util/TokenHelper";

function PerfilPage(){

    const { token } = useContext(AppContext);
    const { data: usuario } = parseJwt(token);

    return(
        <main className="container mt-3">
            <div className="pt-3-md-3 pb-2 mb-3 border-bottom">
                <h2>Mi Perfil</h2>
            </div>
            <div className="mb-3 row">
                <label className="col-sm-2 col-form-label">Nombres</label>
                <div className="col-sm-10">
                    <input type="text" className="form-control" value={usuario.nombres} readOnly />
                </div>
            </div>
            <div className="mb-3 row">
                <label className="col-sm-2 col-form-label">Email</label>
                <div className="col-sm-10">
                    <input type="text" className="form-control" value={usuario.email} readOnly />
                </div>
            </div>
        </main>
    );
}

export default PerfilPage;