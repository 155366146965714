import { Api } from "../util/Api";

export const alumnoTareaService = async (id) => {
    const res = await Api().get('/tareas/alumno/'+id);
    return res;
}

export const detalleTareaService = async (alumno, material) => {
    const res = await Api().get('/tareas/detalle/'+alumno+'/'+material);
    return res;
}

export const subirTareaService = async (datos, opciones) => {
    const res = await Api().post('/tareas/archivo/subir', datos, opciones);
    return res;
}

export const enviarTareaService = async (datos) => {
    const res = await Api().post('/tareas/enviar', datos);
    return res;
}

export const descargarTareaService = async (datos) => {
    const res = await Api().post('/tareas/archivo/descargar', datos, {responseType: 'blob'});
    return res;
}
