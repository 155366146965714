import { API_URL } from "./Config";
import { parseJwt } from "./TokenHelper";
import axios from "axios";

export const Api = () => {
    const token = localStorage.token;
    const { exp } = parseJwt(token);
    const dateNow = Math.floor(new Date().getTime() / 1000); // numericDate format
    if(exp < dateNow){
        localStorage.removeItem("token");
        alert("La sesión ha caducado");
        window.location = "'/";
        return;
    }
    const apiAxios = axios.create({
        baseURL: API_URL,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    });
    return apiAxios;
}
