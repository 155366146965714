import { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { AppContext } from "../context/AppContext";
import { parseJwt } from "../util/TokenHelper";
import { cursoCalificacionesService } from "../services/CursoServices";
import MenuComponent from "../components/MenuComponent";

const initValues = {
    titulo: '',
    rubrica: [],
    calificacion: '',
    comentario: '',
};

function CalificacionesPage() {

    const { token } = useContext(AppContext);
    const { data: usuario } = parseJwt(token);
    const { idcurso } = useParams();
    const [curso,setCurso] = useState({});
    const [tareas,setTareas] = useState([]);
    const [datosModal,setDatosModal] = useState(initValues);
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);

    const handleShowModal = (tarea) => {
        if(tarea.estado!=='P'){
            let datos = {
                titulo: tarea.nombre,
                rubrica: JSON.parse(tarea.rubrica),
                calificacion: tarea.calificacion,
                comentario: tarea.comentario,
            };
            setDatosModal(datos);
            setShowModal(true);
        }
    }

    useEffect(async ()=>{
        const res = await cursoCalificacionesService(usuario.id,idcurso);
        setCurso(res.data.curso);
        if(res.data.tareas!==undefined){
            setTareas(res.data.tareas);
        }
    },[]);

    return(
        <main className="container mt-3">
            <nav className="mb-3 border-bottom">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/principal">Mis Cursos</Link></li>
                    <li className="breadcrumb-item"><Link to={"/curso/"+curso.id}>{curso.nombre}</Link></li>
                    <li className="breadcrumb-item active">Calificaciones</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-3 mb-3">
                    <MenuComponent idcurso={idcurso} />
                    <div className="mt-3">
                        <Link to={"/curso/"+curso.id} className="btn btn btn-outline-dark w-100">Ver curso</Link>
                    </div>
                </div>
                <div className="col-md-9">
                    <h4 className="mb-3"><i className="bi bi-journal-text"></i> {curso.nombre}</h4>
                    <div className="card mb-3">
                        <h5 className="card-header">Calificaciones</h5>
                        <div className="list-group list-group-flush">
                        {tareas.length>0 ? tareas.map((tarea,index)=>{
                            return tarea.fecha_entrega || tarea.calificacion ? (
                                <button key={index} type="button" onClick={()=>handleShowModal(tarea)} className="list-group-item list-group-item-action d-flex justify-content-between">
                                    <div><i className="bi bi-pencil-square"></i> {tarea.nombre}</div>
                                    {tarea.estado==='P'?(
                                        <span className="badge bg-warning text-dark">Pendiente de evaluar</span>
                                    ): tarea.calificacion=='NP' ? (
                                        <div>Nota: <span className="badge bg-danger">NP</span></div>
                                    ): tarea.calificacion>10 ?(
                                        <div>Nota: <span className="badge bg-success">{tarea.calificacion}</span></div>
                                    ):(
                                        <div>Nota: <span className="badge bg-danger">{tarea.calificacion}</span></div>
                                    )}
                                </button>
                            ) : (
                                <Link key={index} to={"/tarea/"+tarea.material_id} className="list-group-item list-group-item-action">
                                    <div className="d-flex justify-content-between">
                                        <div><i className="bi bi-pencil-square"></i> {tarea.nombre}</div>
                                        <span className="badge bg-danger">Pendiente de entregar</span>
                                    </div>
                                </Link>
                            );
                        }) : (
                            <div className="p-2 text-center">No hay calificaciones</div>
                        )}
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} size="lg" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{datosModal.titulo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-8">
                            <h6><i className="bi bi-file-ruled"></i>  Rúbrica</h6>
                            <ul className="list-group">
                            {datosModal.rubrica.map((item, index)=>(
                                <li key={index} className="list-group-item d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-2">
                                        <div>{item.criterio}</div>
                                        Máximo: <strong>{item.puntos}</strong> ptos
                                    </div>
                                    <span className="badge bg-primary rounded-pill">{item.puntaje || 'Pendiente'} ptos</span>
                                </li>
                            ))}
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label">Nota</label>
                                <input type="text" className="form-control bg-white" defaultValue={datosModal.calificacion!=='P' && datosModal.calificacion} readOnly />
                            </div>
                            {datosModal.comentario && (
                            <div className="mb-3">
                                <label className="form-label">Comentario:</label>
                                <textarea className="form-control bg-white" rows="3" defaultValue={datosModal.comentario} readOnly ></textarea>
                            </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </main>
    );
}

export default CalificacionesPage;