import { useState, useEffect, useContext, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { DIR_DOMAIN } from "../util/Config";
import { AppContext } from '../context/AppContext';
import { parseJwt } from "../util/TokenHelper";
import { detalleTareaService, subirTareaService, enviarTareaService, descargarTareaService } from "../services/TareaService";
import MenuComponent from "../components/MenuComponent";

import {ProgressBar} from 'react-bootstrap';
import Swal from 'sweetalert2';
import moment from 'moment';

function MaterialPage() {

    const { idmaterial } = useParams();
    const { token } = useContext(AppContext);
    const { data: usuario } = parseJwt(token);
    const radioRef = useRef({});

    const [material,setMaterial] = useState({});
    const [archivos,setArchivos] = useState([]);
    const [adjuntos,setAdjuntos] = useState([]);
    const [cuestionario,setCuestionario] = useState([]);
    const [puntaje,setPuntaje] = useState(null);
    const [desactivarBoton,setDesactivarBoton] = useState(false);
    const [desactivarEnviar,setDesactivarEnviar] = useState(false);
    const [uploadPercentage,setUploadPercentage]= useState(0);

    const validarEnviarTarea = (arr) => {
        let validate = (!arr || !arr.length) ? true : false;
        if(!validate){
            for(let item of arr){
                if(!item.archivo || item.archivo===''){
                    validate = true;
                    break;
                }
            }
        }
        setDesactivarEnviar(validate);
    } ;

    useEffect(()=>{
        async function fetchData() {
            const { data } = await detalleTareaService(usuario.id, idmaterial);
            setMaterial(data);
            let archivos = JSON.parse(data.archivos);
            setArchivos(archivos);
            if(data.tipo==='C'){
                if(data.tarea_id!==null){
                    const adjuntos = JSON.parse(data.adjuntos);
                    setAdjuntos(adjuntos);
                }
                const cuestionario = JSON.parse(data.descripcion);
                setCuestionario(cuestionario);
            }else{
                let adjuntos = data.tarea_id ? JSON.parse(data.adjuntos) : JSON.parse(data.entregables);
                setAdjuntos(adjuntos);
                validarEnviarTarea(adjuntos);
            }
        }
        fetchData();
    },[usuario.id, idmaterial]);

    const isAfter = (enviado, entrega) => {
        let fechaEnvio = new Date(enviado);
        let fechaEntrega = new Date(entrega);
        return fechaEnvio.getTime() > fechaEntrega.getTime();
    }

    const handleOpenDialog = (idinput) => {
        document.getElementById(idinput).click();
    }

    const onChangeFile = async (e, tipoValido) => {
        let maxSize = 20*1024*1024;
        let filePath  = e.target.value;
        let extension = filePath.split('.').pop();
        let archivoAdjunto = e.target.files[0];
        if(extension.toLowerCase()!==tipoValido){
            e.target.value = '';
            Swal.fire({
                title: 'Archivo incorrecto',
                text: "La extensión del archivo no es la correcta!",
                icon: 'error',
                confirmButtonColor: '#0d6efd',
                confirmButtonText: 'Aceptar'
            });
            return false;
        }else if(archivoAdjunto.size>maxSize){
            e.target.value = '';
            Swal.fire({
                title: 'Tamaño excedido',
                text: "El tamaño del archivo no deber superar los 20MB!",
                icon: 'error',
                confirmButtonColor: '#0d6efd',
                confirmButtonText: 'Aceptar'
            });
            return false;
        }else{
            setDesactivarBoton(true);
            setDesactivarEnviar(true);
            let data = new FormData();
            data.append('dominio', DIR_DOMAIN);
            data.append('codigo', material.curso_codigo);
            data.append('extension', tipoValido);
            data.append('archivo', archivoAdjunto);
            data.append('idtarea', material.tarea_id);
            data.append('idmaterial', material.id);
            data.append('idalumno', usuario.id);
            data.append('alumno', usuario.nombres);
            let options = {
                onUploadProgress: (progressEvent) => {
                    const {loaded, total} = progressEvent;
                    let percent = Math.floor( (loaded * 100) / total );
                    //console.log( `${loaded}kb of ${total}kb | ${percent}%` );
                    if(percent<=100){
                        setUploadPercentage(percent);
                    }
                }
            }
            try {
                let res = await subirTareaService(data, options)
                if(res.status===200){
                    let nombre_archivo = res.data.nombre_archivo;
                    let idtarea = res.data.tarea_id;
                    let nMaterial = {...material, tarea_id:idtarea};
                    setMaterial(nMaterial);
                    let nAdjuntos = adjuntos.map(item=>{
                        if(item.tipo===tipoValido){
                            item.archivo = nombre_archivo;
                        }
                        return item;
                    });
                    setAdjuntos(nAdjuntos);
                    setTimeout(() => {
                        e.target.value = '';
                        setDesactivarBoton(false);
                        validarEnviarTarea(nAdjuntos);
                        setUploadPercentage(0);
                    }, 1000);
                };
            } catch (error) {
                console.log(error);
                e.target.value = '';
                setDesactivarBoton(false);
                setUploadPercentage(0);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDesactivarBoton(true);
        setDesactivarEnviar(true);
        let datos = {
            id: material.tarea_id,
            tipo: material.tipo,
        };
        if(material.tipo==='C'){
            datos.material = material.id;
            const formData = new FormData(e.target);
            let jsonData = {};
            for (var [k, v] of formData) {
                jsonData[k] = v;
            }
            datos.respuestas = jsonData;
        }
        try {
            const { status, data } = await enviarTareaService(datos);
            if(status===200 && data.tipo==='ok'){
                let nMaterial = {...material, fecha_enviado:data.mensaje};
                setMaterial(nMaterial);
                setDesactivarBoton(false);
                if(material.tipo==='C'){
                    if(data.incorrectos.length){
                        for (const idrpta of data.incorrectos) {
                            radioRef.current[idrpta].classList.add('bg-error');
                        }
                    }
                    setPuntaje(data.puntaje);
                }
                const titulo = material.tipo==='C' ? 'Cuestionario enviado' : 'Tarea enviada';
                const texto = material.tipo==='C' ? 'El cuestionario fue enviado correctamente!' : 'La tarea fue enviada correctamente!';
                Swal.fire({
                    title: titulo,
                    text: texto,
                    icon: 'success',
                    confirmButtonColor: '#0d6efd',
                    confirmButtonText: 'Aceptar'
                });
            }else{
                Swal.fire({
                    title: 'No enviado',
                    text: "data.mensaje",
                    icon: 'error',
                    confirmButtonColor: '#0d6efd',
                    confirmButtonText: 'Aceptar'
                });
            }
        } catch (error) {
            console.log(error);
            setDesactivarBoton(false);
            setDesactivarEnviar(false);
        }
    }

    const handleDonwload = async (codigo, archivo, tipo) => {
        let datos = {
            codigo,
            archivo,
            tipo
        };
        try {
            let res = await descargarTareaService(datos);
            if(res.status===200){
                const url = window.URL.createObjectURL(
                    new Blob([res.data]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download',archivo);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return(
        <main className="container mt-3">
            <nav className="mb-3 border-bottom">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/principal"}>Mis Cursos</Link></li>
                    <li className="breadcrumb-item"><Link to={"/curso/"+material.curso_id}>{material.curso_nombre}</Link></li>
                    <li className="breadcrumb-item"><Link to={"/unidad/"+material.unidad_id}>{material.unidad_nombre}</Link></li>
                    <li className="breadcrumb-item active">{material.nombre}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-3 mb-3">
                    <MenuComponent idcurso={material.curso_id} />
                    {/*<div className="list-group">
                        <a href="material.html" className="list-group-item list-group-item-action active" aria-current="true">
                        Tema 01
                        </a>
                        <a href="material.html" className="list-group-item list-group-item-action">Tema 02</a>
                        <a href="tarea.html" className="list-group-item list-group-item-action">Tarea 01</a>
                    </div>*/}
                    <div className="mt-3">
                        <Link to={"/unidad/"+material.unidad_id} className="btn btn-outline-dark w-100">Ver todos los temas</Link>
                    </div>
                </div>
                <div className="col-md-9">
                    <h4 className="mb-3"><Link to={"/unidad/"+material.unidad_id}>{material.unidad}</Link></h4>
                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6">
                                    <i className="bi bi-pencil-square bg-icon"></i> <span className="text-bold">{material.nombre}</span>
                                </div>
                                <div className="col-md-6">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <span className="text-bold">Entrega:</span> {moment(material.fecha_entrega).format('DD/MM/YYYY HH:mm a')}
                                        </div> 
                                        {material.fecha_enviado ? (
                                            <span className={isAfter(material.fecha_enviado,material.fecha_entrega) ? 'badge bg-danger' : 'badge bg-success'}>Enviado</span>
                                        ) : (
                                            <span className={isAfter(moment(),material.fecha_entrega) ? 'badge bg-danger' : 'badge bg-warning text-dark'}>Pendiente</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit} className="card-body">
                        {(material.tipo==='C') ? (
                            !material.calificacion ? (
                            <>
                                <p>Lee atentamente cada pregunta y selecciona una opción como respuesta. Al finalizar, no olvides hacer clic en el botón <strong>"Enviar Cuestionario"</strong>.</p>
                                {cuestionario.map(pregunta => 
                                <div className="p-2 mb-2 rounded-3" style={{background:'#F4F5FC'}} key={pregunta.id}>
                                    <div className="mb-1 fst-italic">{pregunta.descripcion}</div>
                                    {pregunta.respuestas.map(rpta => (
                                        <div className="form-check" ref={elem => radioRef.current[rpta.id] = elem}  id={`respuesta_${rpta.id}`} key={rpta.id}>
                                            <input type="radio" className="form-check-input" name={`respuesta_${pregunta.id}`} id={`radiocheck_${rpta.id}`} value={rpta.id}  required />
                                            <label className="form-check-label" htmlFor={`radiocheck_${rpta.id}`}>
                                                {rpta.descripcion}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                )}
                            </>
                            ) : (
                                <>
                                    <h6>Tus respuestas:</h6>
                                    {adjuntos.map((elem,idx1) =>
                                    <div className="p-2 mb-2 rounded-3" style={{background:'#F4F5FC'}} key={idx1}>
                                        <div className="mb-1 fst-italic">{elem.pregunta}</div>
                                        {elem.respuestas.map((rpta,idx2) => (
                                        <div className={rpta.incorrecto ? "form-check bg-error" : "form-check"} key={idx2}>
                                            <input type="radio" className="form-check-input" checked={rpta.seleccionado} disabled />
                                            <label>
                                                {rpta.descripcion}
                                            </label>
                                        </div>
                                        ))}
                                    </div>
                                    )}
                                </>
                            )
                        ) : (
                            <div dangerouslySetInnerHTML={{__html: material.descripcion}}></div>
                        )}
                        {material.curso_estado==='A' && (<>
                            {archivos && (<>
                                <h6>Recursos:</h6>
                                <ul className="list-group list-group-flush">
                                {archivos.map((item,idx)=>(
                                    <li key={idx} className="list-group-item">
                                        <Link onClick={()=>handleDonwload(material.curso_codigo, item.archivo, 'material')} to="" >
                                            <i className="bi bi-paperclip"></i> {item.archivo}
                                        </Link>
                                    </li>
                                ))}
                                </ul>
                            </>)}
                            <div className="row my-2">
                                <div className="col-md-6">
                                {puntaje && (
                                    <h6>Puntaje obtenido: <span className="badge bg-secondary">{puntaje}</span></h6>
                                )}
                                {(adjuntos && material.tipo!=='C') && (
                                    <h6>Entregables:</h6>
                                )}
                                </div>
                                <div className="col-md-6 text-end">
                                    <small>{material.fecha_enviado ? moment(material.fecha_enviado).format('[Enviado el] DD/MM/YYYY [a las] HH:mm a') : ''}</small>
                                </div>
                            </div>
                            {uploadPercentage > 0 && <ProgressBar className="mb-3" now={uploadPercentage} label={`${uploadPercentage}%`} />}
                            {(adjuntos && material.tipo!=='C') && (<>
                                <ul className="list-group">
                                {adjuntos.map((adjunto, index)=>(
                                    <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                        <span>
                                            {adjunto.archivo ? ( 
                                            <Link onClick={()=>handleDonwload(material.curso_codigo, adjunto.archivo, 'tareas')} to="" >
                                                <i className="bi bi-paperclip"></i> {adjunto.archivo}
                                            </Link>
                                            ) : 
                                            'Archivo '+adjunto.tipo+' sin subir'
                                            }
                                        </span>
                                        {(!material.fecha_enviado && !material.calificacion) && (<>
                                        <button type="button" onClick={()=>handleOpenDialog("inputGroupFile"+index)} className={adjunto.archivo ? 'btn btn-outline-secondary btn-sm w-25' : 'btn btn-danger btn-sm w-25'} disabled={desactivarBoton} >Subir Archivo {adjunto.tipo.toUpperCase()}</button>
                                        <input type="file" onChange={(event)=>onChangeFile(event, adjunto.tipo)} id={"inputGroupFile"+index} style={{display:"none"}} />
                                        </>)}
                                    </li>
                                    ))}
                                </ul>
                                {(material.tipo==='T') && (
                                    <div className="mt-2 text-end fw-bold" style={{'fontSize':'12px'}}>Máximo 20MB por entregable*</div>
                                )}
                            </>)}
                            {!material.calificacion ? (
                            <div className="mt-3" style={{'display': material.estado==='E' ? 'none' : ''}}>
                                <button type="submit" className="btn btn-primary w-100" disabled={desactivarEnviar}>
                                    {(material.tipo==='T') ? 'Entregar Tarea' : 'Enviar Cuestionario'}
                                </button>
                            </div>
                            ) : (
                            <Link className="btn btn-primary w-100 mt-3" to={'/curso/calificaciones/'+material.curso_id}>Tu tarea ha sido calificada</Link>
                            )}
                        </>)}
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default MaterialPage;