import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { parseJwt } from "./TokenHelper";

function AuthGuard({component: Component}){
    const { token, setToken } = useContext(AppContext);
    const { data, exp } = parseJwt(token);
    const dateNow = Math.floor(new Date().getTime() / 1000); // numericDate format
    if(!data || !exp || exp < dateNow){
        localStorage.removeItem("token");
        setToken(null);
        if(exp < dateNow){
            alert("La sesión ha caducado");
        }
    }
    return data ? (
        <Component />
    ) : (
        <Navigate to="/" />
    );
}

export default AuthGuard;