import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { unidadDetalleService } from "../services/CursoServices";
import MenuComponent from "../components/MenuComponent";

function UnidadPage() {

    const { idunidad } = useParams();
    const [unidad,setUnidad] = useState({});
    const [materiales,setMateriales] = useState([]);

    useEffect(()=>{
        async function fetchData() {
            const res = await unidadDetalleService(idunidad);
            setUnidad(res.data.unidad);
            setMateriales(res.data.materiales);
        }
        fetchData();
    },[idunidad]);

    return(
        <main className="container mt-3">
            <nav className="mb-3 border-bottom">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/principal"}>Mis Cursos</Link></li>
                    <li className="breadcrumb-item"><Link to={"/curso/"+unidad.curso_id}>{unidad.curso}</Link></li>
                    <li className="breadcrumb-item active">{unidad.nombre}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-3 mb-3">
                    <MenuComponent idcurso={unidad.curso_id} />
                    {/*<div className="list-group">
                        <a href="#" className="list-group-item list-group-item-action active" aria-current="true">
                        Unidad 01
                        </a>
                        <a href="#" className="list-group-item list-group-item-action">Unidad 02</a>
                        <a href="#" className="list-group-item list-group-item-action">Unidad 03</a>
                        <a href="#" className="list-group-item list-group-item-action">Unidad 04</a>
                        <a href="#" className="list-group-item list-group-item-action">Unidad 05</a>
                    </div>*/}
                    <div className="mt-3">
                        <Link to={"/curso/"+unidad.curso_id} className="btn btn btn-outline-dark w-100">Ver todas las unidades</Link>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="card mb-3">
                        <h4 className="card-header">
                            {unidad.nombre}
                        </h4>
                        <div className="list-group list-group-flush">
                        {materiales.map((material,index)=>(
                            <Link key={index} to={material.tipo==='P' ? "/material/"+material.id : "/tarea/"+material.id} className="list-group-item list-group-item-action" >
                                <i className="bi bi-bookmark"></i> {material.nombre}
                            </Link>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default UnidadPage;