import { UPLOAD_URL, DIR_DOMAIN } from "../util/Config";
import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { parseJwt } from "../util/TokenHelper";
import { cursoListarService } from "../services/CursoServices";
import { alumnoTareaService } from "../services/TareaService";

function PrincipalPage(){

    const { token } = useContext(AppContext);
    const { data: usuario } = parseJwt(token);
    const [listaCursos,setListaCursos] = useState([]);
    const [listaTareas,setListaTareas] = useState([]);

    const listarCursos = async () => {
        const res = await cursoListarService('activos');
        setListaCursos(res.data);
    }

    const listarTareas = async () => {
        const res = await alumnoTareaService(usuario.id);
        setListaTareas(res.data);
    }

    useEffect(()=>{
        listarCursos();
        listarTareas();
    },[]);

    return(
        <main className="container mt-3">
            <div className="pt-3-md-3 pb-2 mb-3 border-bottom">
                <h2>Mis Cursos</h2>
            </div>
            <div className="row">
                <div className="col-md-9">
                    <div className="row">
                    {listaCursos.map((item,index)=>(
                        <div key={index} className="col-md-4 mb-3">
                            <div className="card">
                                <img src={`${UPLOAD_URL}/${DIR_DOMAIN}/materiales/${item.imagen}`} className="card-img-top" alt="curso" />
                                <div className="card-body">
                                    <h5 className="card-title" style={{'height':'44px'}}>{item.nombre}</h5>
                                    <p className="card-text">
                                        <small className="text-muted">
                                            {item.descripcion}<br/>
                                            {item.codigo}
                                        </small>
                                    </p>
                                    <Link to={"/curso/"+item.id} className="btn btn-dark w-100">Ingresar al curso</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
                <div className="col-md-3 border-start">
                    <h5 className="border-bottom py-2"><i className="bi bi-calendar3"></i> Agenda </h5>
                    <ul className="list-group list-group-flush" style={{height:"400px", overflowY:"auto"}}>
                        {listaTareas.map((item,index)=>(
                        <li key={index} className="list-group-item">
                            Vencimiento: {item.fecha_entrega}<br/>
                            <Link to={"/tarea/"+item.id}>{item.nombre}</Link>
                        </li>
                        ))}
                    </ul>
                </div>
            </div>
        </main>
    );
}

export default PrincipalPage;