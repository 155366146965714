import { API_URL, DIR_DOMAIN } from "../util/Config";
import axios from "axios";

const URL = API_URL+"/alumno";

export const loginAlumnoService = async (datos) => {
    datos.dominio = DIR_DOMAIN;
    const res = await axios.post(URL+'/login', datos);
    return res;
}
