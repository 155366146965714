import { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import { SOCKET_URL } from "../util/Config";
import { AppContext } from "../context/AppContext";
import { parseJwt } from "../util/TokenHelper";
import { materialDetalleService } from "../services/CursoServices";

function LaboratorioPage(){

    const { token } = useContext(AppContext);
    const { data: usuario } = parseJwt(token);
    const { idmaterial } = useParams();
    // eslint-disable-next-line no-unused-vars
    const [socket, setSocket] = useState(io(SOCKET_URL));
    const [material, setMaterial] = useState('');
    const [descripcion, setDescripcion] = useState('');

    const mostrarContenido = async () => {
        const { data } = await materialDetalleService(idmaterial);
        setMaterial(data);
        setDescripcion(data.descripcion_laboratorio);
    }

    useEffect(()=>{
        mostrarContenido();
        socket.on('connect', ()=>{
            socket.emit(`channel-alumno`, {idmaterial, nombre: usuario.nombres });
        });
        socket.on(`channel-material-${idmaterial}`, (mensaje)=>{
            setDescripcion(mensaje);
        });
        return () => {
            socket.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <main className="container-fluid mt-3">
            <div className="pt-3-md-3 pb-2 mb-3 sticky-top border-bottom d-flex justify-content-between" style={{'background':'#FFFFFF'}}>
                <h5><i className="bi bi-file-earmark-code-fill text-primary fs-3"></i> {material.curso} | {material.nombre}</h5>
                <div>
                    <Link className="btn btn-secondary btn-sm" to={`/material/${idmaterial}`}><i className="bi bi-arrow-left-circle"></i> Volver al Curso</Link>
                </div>
            </div>
            <div id="contenido-guia" className="p-3" dangerouslySetInnerHTML={{__html: descripcion}}></div>
        </main>
    );
}

export default LaboratorioPage;